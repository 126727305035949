import { Badge, Dropdown, MenuProps } from 'antd'
import React from 'react'
import BELL_ICON from '../../assets/icons/noti/bell.svg'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import useNotificationBell from './useNotificationBell'
import { NotificationBell } from '../../models/Notification'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../app/store'
import Define from '../../constants/define'
import { UserRole } from '../../enum/UserRole'
import {
  NOTIFICATION_PATH,
  NOTI_BELL_CLICK_EVENT,
} from '../../hook/useNotiBellClick'
import { extractCodeAndNom } from './func'
import { chooseClient } from '../../redux/reducers/accountSlice'
import { saveClientCompanyWarehouseToLocal } from '../../utils/localStorage'

export default function NotiBell() {
  const { count, currentCount, notificationBells } = useNotificationBell()
  const { choosingClient, choosingCompany, choosingWarehouse } = useSelector(
    (state: RootState) => state.account
  )
  const role = useSelector((state: RootState) => state.permission.role)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const genToolTip = () => {
    const tooltips: NotificationBell[] = []
    notificationBells.forEach((value) => {
      if (value.count === 0) return
      tooltips.push(value)
    })

    const items: MenuProps['items'] = tooltips.map((tooltip, idx) => {
      const {
        client_id,
        company_id,
        warehouse_id,
        client_code_nom = '',
        company_code_nom = '',
        warehouse_code_nom = '',
        count = 0,
      } = tooltip
      const [codeClient, nomClient] = extractCodeAndNom(client_code_nom)
      const [codeCompany, nomCompany] = extractCodeAndNom(company_code_nom)
      const [codeWarehouse, nomWarehouse] =
        extractCodeAndNom(warehouse_code_nom)
      const item = (
        <div
          className="text-base"
          onClick={() =>
            onNotiClick(
              client_id,
              company_id,
              warehouse_id,
              client_code_nom,
              company_code_nom,
              warehouse_code_nom
            )
          }
        >
          <span>
            <span className="font-bold">{codeClient}</span>_
            <span className="text-gray">{nomClient}</span> |&nbsp;
            <span className="font-bold">{codeCompany}</span>_
            <span className="text-gray">{nomCompany}</span> |&nbsp;
            <span className="font-bold">{codeWarehouse}</span>_
            <span className="text-gray">{nomWarehouse}</span>
          </span>
          :&nbsp;
          <span className="text-red font-bold">{count}</span>
        </div>
      )
      return {
        key: `${idx}`,
        label: item,
      }
    })
    return items
  }

  const getCountDisplay = () => {
    if (role === UserRole.SUPERADMIN) return ''
    if (!choosingClient || !choosingCompany || !choosingWarehouse) return count
    if (count === 0) return ''
    const currentCountBadge = currentCount > 99 ? '99⁺' : currentCount
    const countBadge = count > 99 ? '99⁺' : count
    return `${currentCountBadge}-${countBadge}`
  }

  const onNotiClick = (
    client_id: string,
    company_id: string,
    warehouse_id: string,
    client_code_nom: string,
    company_code_nom: string,
    warehouse_code_nom: string
  ) => {
    if (pathname == NOTIFICATION_PATH) {
      window.dispatchEvent(
        new CustomEvent(NOTI_BELL_CLICK_EVENT, {
          detail: {
            client_code_nom,
            company_code_nom,
            warehouse_code_nom,
            client_id,
            company_id,
            warehouse_id,
          },
        })
      )
    } else {
      dispatch(
        chooseClient({
          client: client_id,
          company: company_id,
          warehouse: warehouse_id,
        })
      )
      saveClientCompanyWarehouseToLocal(
        client_id || '',
        company_id || '',
        warehouse_id || '',
        client_code_nom || '',
        company_code_nom || '',
        warehouse_code_nom || ''
      )
      setTimeout(() => navigate(NOTIFICATION_PATH), 300)
    }
  }

  return (
    <Dropdown
      placement="bottom"
      menu={{ items: role !== UserRole.SUPERADMIN ? genToolTip() : [] }}
    >
      <Link to={'notifications'}>
        <Badge count={getCountDisplay()} size={'small'} title="">
          <img src={BELL_ICON} className="cursor-pointer" alt="noti" />
        </Badge>
      </Link>
    </Dropdown>
  )
}
