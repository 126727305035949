import { Select, SelectProps } from 'antd'
import { t } from 'i18next'
import { ReactNode, useState } from 'react'
import './CustomDropdown.scss'

interface OptionProps {
  value?: string | number
  label?: ReactNode
}

interface CustomDropdownProps {
  title?: string
  options?: OptionProps[]
  onChange?:
    | ((value: any, option: OptionProps | OptionProps[]) => void)
    | undefined
  passValueOnly?: boolean
  showAllOption?: boolean
  onClear?: () => void
  allowClear?: boolean
}

const CustomDropdown = (props: CustomDropdownProps & SelectProps) => {
  const {
    title,
    options,
    onChange,
    onClear,
    passValueOnly,
    showAllOption,
    placeholder,
    style,
    allowClear,
  } = props

  const [isFocused, setIsFocused] = useState(false)

  const handleFocus = () => {
    setIsFocused(true)
  }

  const handleBlur = () => {
    setIsFocused(false)
  }

  return (
    <div className={`custom-dropdown flex items-center justify-between`}>
      <span className="font-bold">{title} </span>
      <Select
        {...props}
        onFocus={handleFocus}
        onBlur={handleBlur}
        bordered={false}
        placeholder={
          <div className="text-sm font-medium text-[#808080] h-full flex items-center">
            {placeholder}
          </div>
        }
        labelInValue
        options={
          showAllOption
            ? [{ label: t('all'), value: '' }, ...(options || [])]
            : options
        }
        style={{
          border: isFocused ? '1px solid #5794F7' : '',
          ...style,
        }}
        onChange={(choice, option) => {
          // @ts-ignore
          onChange(
            choice ? (passValueOnly ? choice.value : choice) : undefined,
            option
          )
        }}
        data-testid="custom-dropdown"
        allowClear={allowClear}
      />
    </div>
  )
}

export default CustomDropdown
