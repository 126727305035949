import { createSlice, PayloadAction } from "@reduxjs/toolkit"

export interface NotiEventPayload {
  notiCount: number
}
interface notiState {
  notiCount: number
}
const initState: notiState = {
  notiCount: 0
}

const notificationSlice = createSlice({
  name: 'notification',
  initialState: initState,
  reducers: {
    pushNewEvent(state) {
      state.notiCount += 1;
    }
  }
})

const notificationReducer = notificationSlice.reducer;
export default notificationReducer;

export const { pushNewEvent } = notificationSlice.actions;