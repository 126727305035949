import { DestinataireCreateUpdateRequest } from '../features/configurations/tier/model/TierModel'
import { DestinataireInterface, ModalGetByIdRequest } from '../models'
import { FilterTierParam } from '../redux/reducers/tierSlice'
import { DestinataireArrayInterface } from './../models/Destinataire'
import baseAxios from './clientAPI'

const destinataireApi = {
  getAllDestinataire(): Promise<DestinataireInterface> {
    const url = '/warehouse/destinataire/all'
    return baseAxios.get(url)
  },

  getDestinataireByName(payload?: {
    code: string
  }): Promise<DestinataireInterface> {
    const url = `/warehouse/destinataire/get`
    const params = {
      data: payload?.code,
    }
    return baseAxios.post(url, params)
  },

  createDestinataire(payload = {}): Promise<DestinataireInterface> {
    const url = '/warehouse/destinataire'
    return baseAxios.post(url, payload)
  },

  searchDestinataireByNameOrCode(payload: {
    data: string
    client_id: string
  }): Promise<DestinataireArrayInterface> {
    const url = '/warehouse/destinataire/search_by_nom_code'

    return baseAxios.post(url, payload)
  },
  getDestinataireByIdElement(
    payload: ModalGetByIdRequest
  ): Promise<DestinataireInterface> {
    let url = `/warehouse/destinataire/get-by-id-element`
    const params = {
      id: payload.id,
      id_contact: payload.id_contact,
      id_add: payload.id_add,
      id_contact_address: payload.id_contact_address,
    }
    return baseAxios.get(url, { params })
  },

  filterDestinataire(params: FilterTierParam):Promise<DestinataireArrayInterface>{
    const url = '/warehouse/destinataire/list';
    return baseAxios.get(url, {params});
  },
  deleteDestinataire(id: string){
    const url = '/warehouse/destinataire/' + id;
    return baseAxios.delete(url)
  },
  getDestinataireById(id: string): Promise<DestinataireInterface>{
    const url = '/warehouse/destinataire'
    return baseAxios.get(url, {params:{id}})
  },
  editDestinataire(payload?: { id: string, data: DestinataireCreateUpdateRequest }): Promise<DestinataireInterface> {
    const url = `/warehouse/destinataire/${payload?.id}`
    return baseAxios.put(url, {...payload?.data})
  },
}

export default destinataireApi
