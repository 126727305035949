import { cloneDeep, forEach, has, set } from 'lodash'
import {
  AppPermission,
  AppPermissionAction,
  PermissionAction,
  PermissionList,
} from '../models/Permission'
import { initPermissionList } from '../redux/reducers/permissionSlice'
import { store } from '../app/store'
import { UserRole } from '../enum/UserRole'
import { INIT_APP_PERMISSIONS } from '../features/configurations/utilisateur/userDetail/utils/constants'
import Cookies from 'js-cookie'
import { getMainDomain } from '../http/clientAPI'

export const isSuperAdmin = () => {
  const { permission } = store.getState()
  return permission.role === UserRole.SUPERADMIN
}

export const mapPermissionList = (
  permissions: (PermissionAction | AppPermissionAction)[] | undefined,
  isApp?: boolean
) => {
  const permissionList = cloneDeep(
    isApp ? INIT_APP_PERMISSIONS : initPermissionList
  ) as any
  // Reset all permissions to false
  resetPermissionList(permissionList)
  // Set permissions of the user to true
  if (permissions) {
    forEach(permissions, ({ action, entity }) => {
      if (has(permissionList, [entity, action])) {
        set(permissionList, [entity, action], true)
      }
    })
  }
  return permissionList
}

export const resetPermissionList = (
  permissionList: PermissionList | AppPermission
) => {
  forEach(permissionList, (permissions) => {
    forEach(permissions, (value, key) => {
      set(permissions, key, false)
    })
  })
}

export const isPermissionEnabled = () => true

export const mapPermissionListToNavbar = (permissionList: PermissionList) => {
  const canAccessStock = permissionList.stock.read
  const canAccessAttendu = permissionList.attendu.read
  const canAccessCommande = permissionList.commande.read
  const canAccessReference = permissionList.reference.read
  const canAccessMission = permissionList.mission.read
  const canAccessHistory = permissionList.history.read
  const canAccessClient = permissionList.client.read
  const canAccessUser = permissionList.user.read
  const canAccessCompany = permissionList.company.read
  const canAccessWarehouse = permissionList.warehouse.read
  const canAccessBilling = permissionList.billing.read
  const canAccessInventory = permissionList.inventory.read

  const items = []
  // dashboard
  items.push({
    title: 'Tableau de bord',
    path: '/tableau-de-bord',
  })

  // gestion de stock
  let hasAtLeastOneItem = false
  const gestionItems = []
  if (canAccessStock) {
    gestionItems.push({
      title: 'Stock',
      path: '/gestion-de-stock/stock',
    })
    hasAtLeastOneItem = true
  }
  if (canAccessAttendu) {
    gestionItems.push({
      title: 'Attendus',
      path: '/gestion-de-stock/attendu',
    })
    hasAtLeastOneItem = true
  }
  if (canAccessCommande) {
    gestionItems.push({
      title: 'Commandes',
      path: '/gestion-de-stock/commande',
    })
    hasAtLeastOneItem = true
  }
  if (canAccessMission) {
    gestionItems.push({
      title: 'Missions',
      path: '/gestion-de-stock/mission',
    })
    hasAtLeastOneItem = true
  }
  if (canAccessHistory) {
    gestionItems.push({
      title: 'Historique',
      path: '/gestion-de-stock/history',
    })
    hasAtLeastOneItem = true
  }
  if (hasAtLeastOneItem) {
    items.push({
      title: 'Gestion de Stock',
      children: gestionItems,
    })
  }

  // billing
  if (canAccessBilling) {
    items.push({
      title: 'Facturation',
      path: '/facturation',
    })
  }

  // inventory
  if (canAccessInventory) {
    items.push({
      title: 'Inventaire',
      path: '/inventaire',
    })
  }

  // configurations
  const configItems = []
  if (canAccessClient) {
    configItems.push({
      title: 'Clients',
      path: '/configurations/client',
    })
  }
  if (canAccessCompany) {
    configItems.push({
      title: 'Société',
      path: '/configurations/societe',
    })
  }
  if (canAccessUser) {
    configItems.push({
      title: 'Utilisateurs',
      path: '/configurations/utilisateur',
    })
  }
  if (canAccessReference) {
    configItems.push({
      title: 'Références',
      path: '/configurations/reference',
    })
  }
  if (canAccessWarehouse) {
    configItems.push({
      title: 'Entrepôts',
    })
  }
  configItems.push({
    title: 'Tiers',
    path: '/configurations/tiers',
  })
  items.push({
    title: 'Configurations',
    children: configItems,
  })
  return items
}

export const setNavbarCookie = (permissionList: PermissionList) => {
  const navbar = mapPermissionListToNavbar(permissionList)
  const stringNavbar = JSON.stringify(navbar)
  const encodedNavbar = encodeURIComponent(stringNavbar)
  const domain = getMainDomain()
  Cookies.set('navbar', encodedNavbar, { domain, expires: 1 })
}

export const isStaging = process.env.REACT_APP_ENV === '"STAGING"'
