import { PayloadAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { all, call, fork, put, take } from 'redux-saga/effects'
import clientWarehouseApi from '../../http/clientWarehouseApi'
import { ClientArrayInterface, ClientInterface } from '../../models'
import {
  getAllClient,
  getAllClientFailed,
  getAllClientSuccess,
  GetAllPayload,
} from '../reducers/clientSlice'
import { chooseClient, chooseClientSuccess } from '../reducers/accountSlice'
import profileApi from '../../http/profile'
import {
  isPermissionEnabled,
  isSuperAdmin,
  mapPermissionList,
  setNavbarCookie,
} from '../../utils/permissions'
import {
  initPermissionList,
  setIsWMSPermission,
  setPermissions,
  setUserActive,
} from '../reducers/permissionSlice'
import { showErrorNotification } from '../../utils/notification'
import { t } from 'i18next'
import { GetPermissionsResponse } from '../../models/Permission'

function* chooseClientSaga(payload: {
  client?: string
  company?: string
  warehouse?: string
}) {
  if (payload.client) {
    try {
      if (isSuperAdmin()) {
        setNavbarCookie(initPermissionList)
      } else if (isPermissionEnabled()) {
        const { client, company, warehouse } = payload
        if (client && company && warehouse) {
          yield call(getPermissionsSaga, client, company, warehouse)
        }
      }
      const item: ClientInterface = yield call(
        clientWarehouseApi.getClientByID,
        payload.client
      )
      yield put(
        chooseClientSuccess({
          ...payload,
          client: item,
        })
      )
    } catch (e) {}
  }
}

function* getPermissionsSaga(
  clientId: string,
  companyId: string,
  warehouseId: string
): any {
  let permissionList
  try {
    const res = yield call(profileApi.getPermissions, {
      clientId,
      companyId,
      warehouseId,
    })
    const data: GetPermissionsResponse = res.data
    const { permissions, active, is_wms_permission } = data
    yield put(setUserActive(active))
    yield put(setIsWMSPermission(is_wms_permission))
    permissionList = mapPermissionList(permissions)
  } catch (error) {
    console.log('getPermissions has an error: ', error)
    showErrorNotification(t('popupErrorGeneral'))
    permissionList = mapPermissionList(undefined)
  } finally {
    yield put(setPermissions({ permissionList }))
    setNavbarCookie(permissionList)
  }
}

function* fetchAllClient(payload: GetAllPayload) {
  try {
    const item: ClientArrayInterface = yield call(
      clientWarehouseApi.getAllClient,
      payload
    )
    yield put(getAllClientSuccess(item))
  } catch (error) {
    if (error instanceof AxiosError) {
      yield put(getAllClientFailed(error.response?.data.error))
    } else {
      yield put(getAllClientFailed('Something went wrong'))
    }
  }
}

function* watchClientFlow() {
  while (true) {
    const type: PayloadAction<GetAllPayload> = yield take(getAllClient.type)
    if (type) {
      yield fork(fetchAllClient, type.payload)
    }
  }
}

function* watchChooseClientFlow() {
  while (true) {
    const type: PayloadAction<{
      client?: string
      company?: string
      warehouse?: string
    }> = yield take(chooseClient.type)
    if (type) {
      yield fork(chooseClientSaga, type.payload)
    }
  }
}

export default function* clientSaga() {
  yield all([fork(watchClientFlow), fork(watchChooseClientFlow)])
}
