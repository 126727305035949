import Define from "../../constants/define";
import { ClientCompanyWareHouse } from "../../models";
import { NotificationBell, Quartet } from "../../models/Notification";

export function triangleToQuartet(triangles: ClientCompanyWareHouse[]){
    const results: Quartet[] = [];
    triangles.forEach(triangle =>{
        const item = triangle as Quartet;
        item.user_id = localStorage.getItem(Define.USER_ID) || '';
        results.push(triangle as Quartet);
    })
    return results;
}

// from the combine of user id, client id, warehouse id and company id to generate a uuid in firestore
export const quartetToDocId = (quartet: Quartet) => `${quartet.client_id}-${quartet.warehouse_id}-${quartet.company_id}-${quartet.user_id}`

export function removeOldNoti(
  quartets: Quartet[],
  noti: Map<string, NotificationBell>
) {
  const newDocIds = quartets.map((quartet) => quartetToDocId(quartet))
  noti.forEach((value, key) => {
    if (!newDocIds.includes(key)) noti.delete(key)
  })
}

export const extractCodeAndNom = (code_nom: string): [string, string] => {
  const index = code_nom.indexOf('_')
  if (index < 0) {
    return [code_nom, code_nom]
  }
  const code = code_nom.substring(0, index)
  const nom = code_nom.substring(index+1)
  return [code, nom]
}