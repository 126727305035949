import { PayloadAction } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { all, call, fork, put, take } from 'redux-saga/effects'
import societeApi from '../../http/societeApi'
import { SocieteArrayInterface } from '../../models'
import {
  GetAllPayload,
  getAllSociete,
  getAllSocieteFailed,
  getAllSocieteSuccess,
} from '../reducers/societeSlice'

function* fetchAllSociete() {
  try {
    const item: SocieteArrayInterface = yield call(societeApi.getAllSociete)
    yield put(getAllSocieteSuccess(item))
  } catch (error) {
    if (error instanceof AxiosError) {
      yield put(getAllSocieteFailed(error.response?.data.error))
    } else {
      yield put(getAllSocieteFailed('Something went wrong'))
    }
  }
}

function* watchSocieteFlow() {
  while (true) {
    const type: PayloadAction<GetAllPayload> = yield take(getAllSociete.type)
    if (type) {
      yield fork(fetchAllSociete)
    }
  }
}

export default function* societeSaga() {
  yield all([fork(watchSocieteFlow)])
}
