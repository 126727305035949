import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { StatusAPI } from '../../enum'
import {
  LotReference,
  NewStock,
  StockType,
  TotalAccessoriesApi,
} from '../../models'
import { Metadata } from '../../models/common/common'
import { ActionRealtimeType } from '../../realtime/type'
import { StatusStock } from '../../enum/StatusStock'
import { ReapproStatus } from '../../enum/StatusCommande'

export interface GetAllPayload {
  limit: number
  offset: number
}

interface currentStockList {
  status: StatusAPI
  stockList: NewStock[]
  loading: boolean
  metadata: Metadata
}

interface totalStockList {
  status: StatusAPI
  number: number
  loading: boolean
}

export interface stockState {
  currentListStock: currentStockList
  error: string
  totalListStock: totalStockList
}

const initialState: stockState = {
  currentListStock: {
    status: StatusAPI.initial,
    stockList: [],
    loading: false,
    metadata: {
      count: 0,
      limit: 0,
      total: 0,
    },
  },
  totalListStock: {
    status: StatusAPI.initial,
    number: 0,
    loading: false,
  },
  error: '',
}

const stockSlice = createSlice({
  name: 'Stock',
  initialState,
  reducers: {
    clearList(state) {
      state.currentListStock = initialState.currentListStock
    },
    clearTotalNumber(state) {
      state.currentListStock = initialState.currentListStock
    },
    getAllStock(state, action: PayloadAction<{ isRealTime: boolean }>) {
      state.currentListStock.status = StatusAPI.calling
      state.currentListStock.loading = !action.payload.isRealTime
    },
    getAllStockSuccess(state, action: PayloadAction<any>) {
      state.currentListStock.status = StatusAPI.success
      state.currentListStock.stockList = action.payload.data.entry
      if (state.currentListStock.stockList) {
        state.currentListStock.stockList.forEach((item) => {
          item.blocage_entree = false
          if (!item.pre_block_status) return
          const pre_block_status = JSON.parse(item.pre_block_status)
          if (!pre_block_status?.blocage_entree) return
          pre_block_status.blocage_entree = false
          item.pre_block_status = JSON.stringify(pre_block_status)
        })
      }

      state.currentListStock.loading = false
      state.currentListStock.metadata = action.payload.data.metadata
    },
    getAllStockFailed(state, action: PayloadAction<any>) {
      state.currentListStock.status = StatusAPI.failure
      state.error = action.payload
      state.currentListStock.loading = false
    },
    // get All stock without filter
    getTotalStock(state, action: PayloadAction) {
      state.totalListStock.status = StatusAPI.calling
      state.totalListStock.loading = true
    },
    getTotalStockSuccess(state, action: PayloadAction<TotalAccessoriesApi>) {
      state.totalListStock.status = StatusAPI.success
      state.totalListStock.number = action.payload.data.total
      state.totalListStock.loading = false
    },
    getTotalStockFailed(state, action: PayloadAction<any>) {
      state.totalListStock.status = StatusAPI.failure
      state.error = action.payload
      state.totalListStock.loading = false
    },
    addDetailStock(state, action: PayloadAction<any>) {
      state.currentListStock.stockList = [
        ...state.currentListStock.stockList,
      ].map((item) => {
        if (item.id === action.payload.stockParentId) {
          return {
            ...item,
            stock: [
              ...(item.stock || []),
              ...action.payload.newItems.filter(
                (i: StockType) =>
                  !item.stock?.find((currentItem) => currentItem.id === i.id)
              ),
            ],
            total: action.payload.meta.total,
          }
        } else {
          return item
        }
      })
    },
    setStockListRealtime(
      state,
      action: PayloadAction<{
        referenceChange: NewStock
        actionType: ActionRealtimeType
      }>
    ) {},
    // update main line stock when reference is updated
    updateReferenceStock(
      state,
      action: PayloadAction<{
        referenceChange: NewStock
        actionType: ActionRealtimeType
        totalRecord?: number
      }>
    ) {
      const { actionType, referenceChange, totalRecord } = action.payload
      let data = state.currentListStock.stockList
      const currentDataCount = state.currentListStock.metadata.total
      const searchParams = new URLSearchParams(window.location.search)

      function deleteRow() {
        // decrease total attendu number
        state.totalListStock.number = state.totalListStock.number
          ? state.totalListStock.number - 1
          : 0
        // update number of records in current page of attendu
        state.currentListStock.metadata.total = totalRecord || 0
        // remove deleted attendu from list if it is not full and deleted record is found in current list
        if (currentDataCount > (totalRecord || 0)) {
          const index = data.findIndex((item) => item.id === referenceChange.id)
          if (data?.length && index !== -1) {
            data.splice(index, 1)
            state.currentListStock.stockList = data
          } else {
            const condition = Object.fromEntries(
              new URLSearchParams(window.location.search)
            )
            const isLastPage =
              Math.round(
                (Number(totalRecord) + 1) / parseInt(condition['page-size'])
              ) +
                1 ===
              parseInt(condition['page-index'] || '1')
            if (isLastPage) {
              state.currentListStock.stockList.splice(0, 1)
            }
          }
        }
      }

      switch (actionType) {
        case ActionRealtimeType.UPDATE:
          if (data?.length) {
            if (referenceChange?.deleted_at) {
              deleteRow()
            } else {
              const referenceUpdatedIndex = data.findIndex(
                (item) => item.id === referenceChange.id
              )
              if (referenceUpdatedIndex >= 0) {
                // @ts-ignore
                delete referenceChange.stock
                state.currentListStock.stockList[referenceUpdatedIndex] = {
                  ...data[referenceUpdatedIndex],
                  ...referenceChange,
                }
              }
            }
          }
          break
        case ActionRealtimeType.CREATE:
          // increase total attendu number
          state.totalListStock.number = state.totalListStock.number
            ? state.totalListStock.number + 1
            : 1
          // update number of records in current page of attendu
          if (totalRecord && currentDataCount < totalRecord) {
            state.currentListStock.metadata.total++
          }
          if (
            (!data ||
              data?.length < parseInt(searchParams.get('page-size') || '0')) &&
            totalRecord &&
            (!currentDataCount || currentDataCount < totalRecord)
          ) {
            if (data) {
              data.push(referenceChange)
            } else {
              data = [referenceChange]
            }
            state.currentListStock.stockList = data
          }
          break
        case ActionRealtimeType.DELETE:
          deleteRow()
          break
      }
    },
    updateStockToReference(
      state,
      action: PayloadAction<{
        stockChange: StockType
        actionType: ActionRealtimeType
      }>
    ) {
      const { stockChange, actionType } = action.payload
      if (state?.currentListStock?.stockList?.length) {
        const referenceUpdatedIndex =
          state.currentListStock.stockList.findIndex(
            (item) => item.id === stockChange?.reference_id
          )
        if (referenceUpdatedIndex >= 0) {
          const currentReference =
            state.currentListStock.stockList[referenceUpdatedIndex]
          if (actionType === ActionRealtimeType.CREATE) {
            state.currentListStock.stockList[referenceUpdatedIndex] = {
              ...currentReference,
              stock: [
                ...(currentReference.stock || []),
                {
                  ...stockChange,
                  // @ts-ignore
                  att_production: stockChange.att_production_ob,
                },
              ],
            }
          } else if (actionType === ActionRealtimeType.UPDATE) {
            const stockIndexUpdate = currentReference.stock.findIndex(
              (item) => item.id === stockChange.id
            )
            if (stockIndexUpdate >= 0) {
              if (
                stockChange?.deleted_at ||
                (stockChange?.status === StatusStock.SORTI &&
                  [
                    ReapproStatus.MissionCreated,
                    ReapproStatus.MissionCompleted,
                    // @ts-ignore
                  ].includes(stockChange?.reappro_status))
              ) {
                state.currentListStock.stockList[
                  referenceUpdatedIndex
                ].stock.splice(stockIndexUpdate, 1)
              } else {
                state.currentListStock.stockList[referenceUpdatedIndex].stock[
                  stockIndexUpdate
                ] = {
                  ...currentReference.stock[stockIndexUpdate],
                  ...stockChange,
                  // @ts-ignore
                  att_production: stockChange.att_production_ob,
                }
              }
            }
          }
        }
      }
    },
    updateLotToReference(state, action: PayloadAction<LotReference>) {
      const { reference_id, id } = action.payload
      const stockList = state?.currentListStock?.stockList || []
      if (stockList?.length) {
        const referenceUpdatedIndex = stockList.findIndex(
          (item) => item.id === reference_id
        )
        if (referenceUpdatedIndex >= 0) {
          const lotReferenceIndex = stockList[
            referenceUpdatedIndex
          ].lot_reference?.findIndex((item) => item.id === id)
          if (lotReferenceIndex !== undefined && lotReferenceIndex >= 0) {
            // @ts-ignore
            state.currentListStock.stockList[
              referenceUpdatedIndex
            ].lot_reference[lotReferenceIndex] = action.payload
          }
        }
      }
    },
  },
})

const stockReducer = stockSlice.reducer
export default stockReducer

export const {
  clearList,
  getAllStock,
  getAllStockFailed,
  getAllStockSuccess,
  clearTotalNumber,
  getTotalStock,
  getTotalStockFailed,
  getTotalStockSuccess,
  updateReferenceStock,
  updateStockToReference,
  setStockListRealtime,
  updateLotToReference,
} = stockSlice.actions
