import Define from '../constants/define'
import baseAxios from './clientAPI'
import { getUrlFilterParams } from '../utils'
import {
  TotalAccessoriesApi,
  ExportStockType,
  ExportResponseInterface,
  StockArrayInterface,
  LocationsArrayInterface,
} from '../models'

const stockApi = {
  getAllStock(payload: {}): Promise<StockArrayInterface> {
    const url = '/warehouse/reference/getall'
    const { condition, pageSize, pageIndex } = getUrlFilterParams()
    const params = {
      limit: pageSize,
      offset: (pageIndex - 1) * pageSize || 0,
      client_id: localStorage.getItem(Define.CHOOSING_CLIENT),
      company_id: localStorage.getItem(Define.CHOOSING_COMPANY),
      warehouse_id: localStorage.getItem(Define.CHOOSING_WAREHOUSE),
      ...condition,
    }
    return baseAxios.get(url, { params })
  },

  getAvailableLocation(payload: {}): Promise<LocationsArrayInterface> {
    const url = '/warehouse/location/available'
    const { pageSize, pageIndex } = getUrlFilterParams()
    const params = {
      limit: pageSize,
      offset: (pageIndex - 1) * pageSize || 0,
      client_id: localStorage.getItem(Define.CHOOSING_CLIENT),
      company_id: localStorage.getItem(Define.CHOOSING_COMPANY),
      warehouse_id: localStorage.getItem(Define.CHOOSING_WAREHOUSE),
    }

    return baseAxios.get(url, { params })
  },

  getAllStockDashboard(payload: any): Promise<StockArrayInterface> {
    const url = '/warehouse/reference/getall'

    const params = {
      limit: 1,
      offset: 0,
      client_code_nom: localStorage.getItem(Define.CHOOSING_CLIENT_CODENOM),
      company_code_nom: localStorage.getItem(Define.CHOOSING_COMPANY_CODENOM),
      warehouse_code_nom: localStorage.getItem(
        Define.CHOOSING_WAREHOUSE_CODENOM
      ),
      ...payload,
    }
    return baseAxios.get(url, { params })
  },

  getTotalStock(payload: {}): Promise<TotalAccessoriesApi> {
    const url = '/warehouse/accessories/get-total'
    const params = {
      client_id: localStorage.getItem(Define.CHOOSING_CLIENT),
      company_id: localStorage.getItem(Define.CHOOSING_COMPANY),
      warehouse_id: localStorage.getItem(Define.CHOOSING_WAREHOUSE),
      type: 'stock',
    }
    return baseAxios.get(url, { params })
  },

  exportAllStock(data: ExportStockType): Promise<ExportResponseInterface> {
    const url = '/warehouse/reference/export'
    return baseAxios.post(url, data)
  },
}

export default stockApi

export function loadMoreStockDetail(id: string, limit: number, offset: number) {
  const url = '/warehouse/stock/load-more-by-id'
  const params = {
    limit: limit,
    offset: offset,
    id,
    client_id: localStorage.getItem(Define.CHOOSING_CLIENT),
    company_id: localStorage.getItem(Define.CHOOSING_COMPANY),
    warehouse_id: localStorage.getItem(Define.CHOOSING_WAREHOUSE),
  }
  return baseAxios.get(url, { params })
}
