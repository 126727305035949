import { FC, useState } from 'react'
import accountError from '../../../assets/icons/accout-error.svg'
import { t } from 'i18next'
import { logout } from '../../../http/clientAPI'
import { Spin } from 'antd'
import './AccountError.scss'

type ErrorType = 'inactiveAccount' | 'noWMSPermission'

interface AccountErrorProps {
  type: ErrorType
}

const AccountError: FC<AccountErrorProps> = ({ type }) => {
  const [loading, setLoading] = useState<boolean>(false)

  const onLogout = () => {
    setLoading(true)
    logout()
  }

  return (
    <div className="accountError mt-16 py-[30px] px-[100px] text-primary">
      <div className="font-semibold text-2xl uppercase">{t('access')}</div>
      <div className="flex justify-center">
        <div className="content w-[827px] text-center">
          <div className="flex justify-center">
            <img src={accountError} alt="account-error" />
          </div>
          <div className="title font-bold text-[75px] mb-[40px]">
            {type === 'inactiveAccount'
              ? t('inactiveAccount')
              : t('noWMSPermission')}
          </div>
          <div className="contactAdmin font-medium text-4xl mb-[60px]">
            {t('contactAdmin')}
          </div>
          <div className="flex justify-center">
            <div
              className="logoutBtn w-[252px] h-[42px] rounded-[7px] bg-secondary text-white font-bold text-[19px] flex justify-center items-center cursor-pointer uppercase"
              onClick={onLogout}
            >
              {loading ? <Spin className="custom-spin" /> : t('disconnect')}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AccountError
