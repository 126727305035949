import { FournisseurCreateUpdateRequest } from '../features/configurations/tier/model/TierModel'
import {
  FournisseurArrayInterface,
  FournisseurInterface,
} from '../models/Fournisseur'
import baseAxios from './clientAPI'
export type FilterFournisseurParam = {
  offset: number
  limit: number
  search?: string
  client_id?: string | null
}
const fournisseurApi = {
  getAllFournisseur(): Promise<FournisseurInterface> {
    const url = '/warehouse/fournisseur/all'
    return baseAxios.get(url)
  },

  getFournisseurByName(payload?: {
    code: string
  }): Promise<FournisseurInterface> {
    const url = `/warehouse/fournisseur/get`
    const params = {
      data: payload?.code,
    }
    return baseAxios.post(url, params)
  },

  getFournisseurByID(payload?: { id: string }): Promise<FournisseurInterface> {
    const url = `/warehouse/fournisseur?id=${payload?.id}`
    return baseAxios.get(url)
  },

  createFournisseur(payload = {}): Promise<FournisseurInterface> {
    const url = '/warehouse/fournisseur'
    return baseAxios.post(url, payload)
  },

  searchFournisseurByNameOrCode(payload: {
    data: string
    client_id: string
  }): Promise<FournisseurArrayInterface> {
    const url = '/warehouse/fournisseur/search_by_nom_code'
    return baseAxios.post(url, payload)
  },

  searchAllFournisseur(payload: {
    search?: string
    client_id?: string
  }): Promise<FournisseurArrayInterface> {
    const url = '/warehouse/fournisseur/list'
    return baseAxios.get(url, { params: payload })
  },

  filterFournisseur(
    params: FilterFournisseurParam
  ): Promise<FournisseurArrayInterface> {
    const url = '/warehouse/fournisseur/list'
    return baseAxios.get(url, { params })
  },
  deleteFournisseur(id: string) {
    const url = '/warehouse/fournisseur/' + id
    return baseAxios.delete(url)
  },
  editFournisseur(payload?: {
    id: string
    data: FournisseurCreateUpdateRequest
  }): Promise<FournisseurInterface> {
    const url = `/warehouse/fournisseur/${payload?.id}`
    return baseAxios.put(url, { ...payload?.data })
  },
}

export default fournisseurApi
