export enum ActionRealtimeType {
  CREATE = 'INSERT',
  UPDATE = 'UPDATE',
  DELETE = 'DELETE',
}

export type RealTimeData<T> = {
  id: string
  timestamp: string
  type: string
  action: ActionRealtimeType
  event: T
}
