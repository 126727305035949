export enum TierRoute {
  Fournisseurs = '/configurations/tiers/fournisseurs',
  Transporteurs = '/configurations/tiers/transporteurs',
  Destinataires = '/configurations/tiers/destinataires',
  FournisseursCreate = '/configurations/tiers/fournisseurs/create',
  TransporteursCreate = '/configurations/tiers/transporteurs/create',
  DestinatairesCreate = '/configurations/tiers/destinataires/create',
  FournisseursEditPattern = '/configurations/tiers/fournisseurs/entity/[a-zA-Z0-9\-]+',
  TransporteursEditPattern = '/configurations/tiers/transporteurs/entity/[a-zA-Z0-9\-]+',
  DestinatairesEditPattern = '/configurations/tiers/destinataires/entity/[a-zA-Z0-9\-]+'

}