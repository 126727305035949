import {
  ClientArrayInterface,
  ClientInterface,
  SocieteEntrepotInterface,
} from '../models'
import baseAxios from './clientAPI'
import {
  ClientFilterArrayInterface,
  CompaniesFilterArrayInterface,
  WarehousesFilterArrayInterface,
} from '../redux/reducers/accountSlice'
import { FilterAccountType } from '../models/Account'
import Define from '../constants/define'
import { getUrlFilterParams } from '../utils'
const clientWarehouseApi = {
  createClient(body: {}): Promise<ClientInterface> {
    const url = '/warehouse/client'
    return baseAxios.post(url, body)
  },

  updateClient(body: {}): Promise<ClientInterface> {
    const url = '/warehouse/client'
    return baseAxios.patch(url, body)
  },

  deleteClient(id: string): Promise<ClientInterface> {
    const url = `/warehouse/client/${id}`
    return baseAxios.delete(url)
  },

  deleteClients(ids: string[]): Promise<ClientInterface> {
    const url = `/warehouse/client?ids=${ids.join(',')}`
    return baseAxios.delete(url)
  },

  getAllClient(payload: {
    limit: Number
    offset: Number
  }): Promise<ClientArrayInterface> {
    const { condition, pageSize, pageIndex } = getUrlFilterParams()
    const url = '/warehouse/client'
    return baseAxios.get(url, {
      params: {
        limit: payload.limit || pageSize,
        offset: payload.offset || (pageIndex - 1) * pageSize || 0,
        ...condition,
      },
    })
  },

  getSocieteByUserId(payload: {
    userId: string
  }): Promise<SocieteEntrepotInterface> {
    const url = `/warehouse/client/company/${payload.userId}`
    return baseAxios.get(url)
  },
  //get filter account's information
  getFilterClient(
    data: FilterAccountType
  ): Promise<CompaniesFilterArrayInterface> {
    return baseAxios.get(`/warehouse/accessories/filter-client`, {
      params: data,
    })
  },

  getFilterCompany(
    data: FilterAccountType
  ): Promise<ClientFilterArrayInterface> {
    return baseAxios.get(`/warehouse/accessories/filter-company`, {
      params: data,
    })
  },

  getFilterWarehouse(
    data: FilterAccountType
  ): Promise<WarehousesFilterArrayInterface> {
    return baseAxios.get(`/warehouse/accessories/filter-warehouse`, {
      params: data,
    })
  },

  getClientByCodeNom(code: string): Promise<ClientInterface> {
    const url = `/warehouse/client/code-nom?code_nom=${code}`
    return baseAxios.get(url)
  },

  getClientByID(id: string): Promise<ClientInterface> {
    const url = `/warehouse/client/${id}`
    return baseAxios.get(url)
  },

  getLevel(client: string, warehouse: string, company: string) {
    const url = 'warehouse/accessories/filter-niveau'
    return baseAxios.get(url, {
      params: {
        client_id: client || localStorage.getItem(Define.CHOOSING_CLIENT),
        company_id: company || localStorage.getItem(Define.CHOOSING_COMPANY),
        warehouse_id:
          warehouse || localStorage.getItem(Define.CHOOSING_WAREHOUSE),
      },
    })
  },
}

export default clientWarehouseApi
