import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ReferenceArrayInterface, ReferenceRequest } from '../../models'
import { Metadata } from '../../models/common/common'
import { GetAllPayload } from './attenduSlice'

interface currentReferenceList {
  referenceList: ReferenceRequest[]
  loading: boolean
  error: string
  metadata: Metadata
}

interface referenceState {
  currentReferenceList: currentReferenceList
}

const initialState: referenceState = {
  currentReferenceList: {
    referenceList: [],
    loading: false,
    error: '',
    metadata: {
      count: 0,
      limit: 0,
      total: 0,
    },
  },
}

const referenceSlice = createSlice({
  name: 'reference',
  initialState,
  reducers: {
    getAllReference(state, action: PayloadAction<{}>) {
      state.currentReferenceList.loading = true
    },
    getAllReferenceSuccess(
      state,
      action: PayloadAction<ReferenceArrayInterface>
    ) {
      state.currentReferenceList.loading = false
      state.currentReferenceList.referenceList = action.payload.data.data
      state.currentReferenceList.metadata = action.payload.data.metadata
    },
    getAllReferenceFailed(state, action: PayloadAction<string>) {
      state.currentReferenceList.loading = false
      state.currentReferenceList.error = action.payload
    },
  },
})

const referenceReducer = referenceSlice.reducer
export default referenceReducer

export const {
  getAllReference,
  getAllReferenceSuccess,
  getAllReferenceFailed,
} = referenceSlice.actions
