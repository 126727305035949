import { notification } from 'antd'
import Error from '../assets/icons/icon-error.svg'
import CloseIcon from '../assets/images/iconClose.png'
import SUCCESS from '../assets/images/success.png'
import { t } from 'i18next'
import CLOSE_ICON from '../assets/icons/close-modal.svg'

// This function checks if a notification with the given text is displaying
function isNotificationWithTextDisplayed(text: string) {
  const elements = document.querySelectorAll(`.ant-notification-notice-message`)
  for (let element of elements) {
    if (element.textContent?.includes(text)) {
      return true
    }
  }
  return false
}

// Prevent showing multiple error notifications at the same time
let lastNotificationTime = 0

export const showErrorNotification = (message?: string) => {
  const currentTime = Date.now()
  if (currentTime - lastNotificationTime >= 100) {
    if (!isNotificationWithTextDisplayed(message || t('errorHasOccurred'))) {
      showErrorMessage(message || t('errorHasOccurred'))
      lastNotificationTime = currentTime
    }
  }
}

export const showErrorMessage = (message: string, duration?: number) =>
  notification.open({
    className: 'noti noti-error',
    message: (
      <div className="flex items-center">
        <img src={Error} alt="error" width={50} style={{ marginRight: 10 }} />
        {message}
      </div>
    ),
    placement: 'topRight',
    closeIcon: <img src={CloseIcon} alt="close" />,
    duration: duration || 5,
  })

export const showSuccessNotification = (message?: string) => {
  notification.open({
    className: 'noti noti-success',
    message: (
      <div className="flex items-center">
        <img src={SUCCESS} alt="success" width={50} className="mr-2.5" />
        {message || ''}
      </div>
    ),
    placement: 'topRight',
    closeIcon: <img src={CloseIcon} alt="close" className="ml-28" />,
    duration: 3,
  })
}

export const showPermissionError = () => {
  showErrorNotification(t('noPermissionError'))
}

export const showNotification = (message: string) => {
  notification.open({
    className: 'noti',
    message: <div className="flex items-center text-sm px-3">{message}</div>,
    placement: 'topRight',
    closeIcon: <img src={CLOSE_ICON} alt="close" className="w-[15px]" />,
    duration: 5,
  })
}
