import Define from '../constants/define'

export const saveClientCompanyWarehouseToLocal = (
  clientId: string,
  companyId: string,
  warehouseId: string,
  clientCodeNom: string,
  companyCodeNom: string,
  warehouseCodeNom: string
) => {
  localStorage.setItem(Define.CHOOSING_CLIENT, clientId || '')
  localStorage.setItem(Define.CHOOSING_COMPANY, companyId || '')
  localStorage.setItem(Define.CHOOSING_WAREHOUSE, warehouseId || '')
  localStorage.setItem(Define.CHOOSING_CLIENT_CODENOM, clientCodeNom || '')
  localStorage.setItem(Define.CHOOSING_COMPANY_CODENOM, companyCodeNom || '')
  localStorage.setItem(
    Define.CHOOSING_WAREHOUSE_CODENOM,
    warehouseCodeNom || ''
  )
}

export const getLocalClientCompanyWarehouse = () => {
  let localClient = localStorage.getItem(Define.CHOOSING_CLIENT) || ''
  let localClientCodenom =
    localStorage.getItem(Define.CHOOSING_CLIENT_CODENOM) || ''
  let localCompany = localStorage.getItem(Define.CHOOSING_COMPANY) || ''
  let localWarehouse = localStorage.getItem(Define.CHOOSING_WAREHOUSE) || ''
  let localCompanyNom =
    localStorage.getItem(Define.CHOOSING_COMPANY_CODENOM) || ''
  let localWarehouseNom =
    localStorage.getItem(Define.CHOOSING_WAREHOUSE_CODENOM) || ''
  return {
    localClient,
    localClientCodenom,
    localCompany,
    localWarehouse,
    localCompanyNom,
    localWarehouseNom,
  }
}
