import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ActionRealtimeType } from '../../realtime/type'

export interface realtimeState {
  actionRealtimeType?: ActionRealtimeType
}

const initialState: realtimeState = {
  actionRealtimeType: undefined,
}

const realtimeSlice = createSlice({
  name: 'realtime',
  initialState,
  reducers: {
    updateRealtimeStatus(state, action: PayloadAction<ActionRealtimeType>) {
      state.actionRealtimeType = action.payload
    },
    resetRealtimeStatus(state) {
      state.actionRealtimeType = undefined
    },
  },
})

const realtimeReducer = realtimeSlice.reducer

export default realtimeReducer

export const { updateRealtimeStatus, resetRealtimeStatus } =
  realtimeSlice.actions
