import {
  getAllStock,
  getAllStockFailed,
  getAllStockSuccess,
  GetAllPayload,
  getTotalStockSuccess,
  getTotalStockFailed,
  getTotalStock,
  clearList,
  clearTotalNumber,
  updateReferenceStock,
  setStockListRealtime,
} from '../reducers/stockSlice'
import { all, call, fork, put } from '@redux-saga/core/effects'
import { AxiosError } from 'axios'
import { PayloadAction } from '@reduxjs/toolkit'
import { take } from 'redux-saga/effects'
import stockApi from '../../http/stockApi'
import {
  NewStock,
  StockArrayInterface,
  TotalAccessoriesApi,
  LocationsArrayInterface,
} from '../../models'
import Define from '../../constants/define'
import { ActionRealtimeType } from '../../realtime/type'
import { updateRealtimeStatus } from '../reducers/realtimeSlice'
import { getUrlFilterParams, locationsToStockTransformer } from '../../utils'
import { Divers } from '../../enum/Divers'

function* fetchAllStocks(payload: GetAllPayload) {
  try {
    if (localStorage.getItem(Define.CHOOSING_CLIENT)) {
      let item = {} as StockArrayInterface
      const { condition } = getUrlFilterParams()
      if (condition?.divers === Divers.EMPTY_SPACE) {
        const locations: LocationsArrayInterface = yield call(
          stockApi.getAvailableLocation,
          payload
        )
        item.data = {
          entry: locationsToStockTransformer(locations.data.locations),
          metadata: locations.data.metadata,
        }
      } else {
        item = yield call(stockApi.getAllStock, payload)
      }
      yield put(getAllStockSuccess(item))
    } else {
      yield put(clearList())
    }
  } catch (error) {
    if (error instanceof AxiosError) {
      yield put(getAllStockFailed(error.response?.data.error))
    } else {
      yield put(getAllStockFailed('Something went wrong'))
    }
  }
}

function* fetchTotalStocks(payload: GetAllPayload) {
  try {
    if (localStorage.getItem(Define.CHOOSING_CLIENT)) {
      const item: TotalAccessoriesApi = yield call(
        stockApi.getTotalStock,
        payload
      )
      yield put(getTotalStockSuccess(item))
    } else {
      yield put(clearTotalNumber())
    }
  } catch (error) {
    if (error instanceof AxiosError) {
      yield put(getTotalStockFailed(error.response?.data.error))
    } else {
      yield put(getTotalStockFailed('Something went wrong'))
    }
  }
}

function* updateReferenceStocks(payload: {
  referenceChange: NewStock
  actionType: ActionRealtimeType
}) {
  try {
    if (
      [ActionRealtimeType.CREATE, ActionRealtimeType.DELETE].includes(
        payload.actionType
      ) ||
      payload.referenceChange?.deleted_at
    ) {
      const res: TotalAccessoriesApi = yield call(stockApi.getTotalStock, {})
      yield put(
        updateReferenceStock({
          ...payload,
          totalRecord: res.data.total,
        })
      )
      if (payload.referenceChange?.deleted_at) {
        yield put(updateRealtimeStatus(ActionRealtimeType.DELETE))
      } else {
        yield put(updateRealtimeStatus(payload.actionType))
      }
    } else {
      yield put(updateReferenceStock(payload))
    }
  } catch (error) {}
}

function* watchStockFlow() {
  while (true) {
    const type: PayloadAction<GetAllPayload> = yield take(getAllStock.type)
    if (type) {
      yield fork(fetchAllStocks, type.payload)
    }
  }
}

function* watchTotalFlow() {
  while (true) {
    const type: PayloadAction<GetAllPayload> = yield take(getTotalStock.type)
    if (type) {
      yield fork(fetchTotalStocks, type.payload)
    }
  }
}

function* watchReferenceRealtimeFlow() {
  while (true) {
    const type: PayloadAction<any> = yield take(setStockListRealtime.type)
    if (type) {
      yield fork(updateReferenceStocks, type.payload)
    }
  }
}

export default function* stockSaga() {
  yield all([
    fork(watchStockFlow),
    fork(watchTotalFlow),
    fork(watchReferenceRealtimeFlow),
  ])
}
