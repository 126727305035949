import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { PermissionList } from '../../models/Permission'
import { UserRole } from '../../enum/UserRole'
import { RoleTemplate } from '../../models'
import { resetPermissionList } from '../../utils/permissions'

interface PermissionState {
  role: UserRole
  permissionList: PermissionList
  roleTemplates: RoleTemplate[]
  active: boolean
  isWMSPermission: boolean
  isAppPermission: boolean
}

export const initPermissionList: PermissionList = {
  dashboard: {
    read_stock: true,
    read_personel: true,
    read_warehouse: true,
  },
  stock: {
    read: true,
    update: true,
    export: true,
  },
  attendu: {
    read: true,
    create: true,
    update: true,
    delete: true,
    import: true,
    export: true,
  },
  commande: {
    read: true,
    create: true,
    update: true,
    delete: true,
    import: true,
    export: true,
  },
  reference: {
    read: true,
    create: true,
    update: true,
    delete: true,
    import: true,
    export: true,
  },
  mission: {
    read: true,
    upsert_stock: true,
    upsert_attendu: true,
    upsert_commande: true,
    delete_stock: true,
    delete_attendu: true,
    delete_commande: true,
  },
  history: {
    read: true,
  },
  client: {
    read: true,
    create: true,
    update: true,
    delete: true,
    export: true,
  },
  user: {
    read: true,
    create: true,
    update: true,
    delete: true,
    export: true,
  },
  company: {
    read: true,
    create: true,
    update: true,
    delete: true,
    export: true,
  },
  warehouse: {
    read: true,
  },
  billing: {
    read: true,
  },
  inventory: {
    read: true,
  },
  fournisseur: {
    read: true,
    create: true,
  },
  transporteur: {
    read: true,
    create: true,
    update: true,
    delete: true,
  },
  destinataire: {
    read: true,
    create: true,
    update: true,
    delete: true,
  },
}

const initialState: PermissionState = {
  role: UserRole.SUPERADMIN,
  permissionList: initPermissionList,
  roleTemplates: [],
  active: true,
  isWMSPermission: true,
  isAppPermission: true,
}

const permissionSlice = createSlice({
  name: 'permission',
  initialState,
  reducers: {
    setPermissions(
      state,
      action: PayloadAction<{ permissionList: PermissionList }>
    ) {
      state.permissionList = action.payload.permissionList
    },
    setRole(state, action: PayloadAction<any>) {
      state.role = action.payload.role
    },
    setRoleTemplates(state, action: PayloadAction<RoleTemplate[]>) {
      state.roleTemplates = action.payload
    },
    resetPermissions(state) {
      resetPermissionList(state.permissionList)
    },
    setUserActive(state, action: PayloadAction<boolean>) {
      state.active = action.payload
    },
    setIsWMSPermission(state, action: PayloadAction<boolean>) {
      state.isWMSPermission = action.payload
    },
  },
})

const permissionReducer = permissionSlice.reducer

export default permissionReducer

export const {
  setPermissions,
  setRole,
  setRoleTemplates,
  resetPermissions,
  setUserActive,
  setIsWMSPermission,
} = permissionSlice.actions
